html,
body,
#root
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


body {
  margin: 0;
  font-family: "brandon-grotesque",source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #cdcbca;
}

h1,h2,h3,h4,h5 {
  text-transform: uppercase;
  letter-spacing: 1.03px;
  line-height: 1.2em;
}


h1 {
  background: -webkit-linear-gradient(0deg,#cdcbca, aliceblue,#cdcbca,#2a3a3f);
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important;
}

.btn {
  background-color: black;
  border: 2px solid white;
  margin-right:20px;
  margin-top:0px;
  background-color: white;
  color: black;
  padding: 10px 20px;
  transition: background-color 0.2s, color 0.2s;
}

.btn-green{
  background-color: greenyellow;
  border: 2px solid white;
  margin-right:20px;
  margin-top:0px;
  background-color: greenyellow;
  color: black;
  padding: 10px 20px;
  transition: background-color 0.2s, color 0.2s;
}
.btn-green:hover {
  color: white;
  background-color: black;
}

.btn:hover {
  color: white;
  background-color: black;
}

h2 {
  background: -webkit-linear-gradient(0deg,#cdcbca, aliceblue,#cdcbca,#2a3a3f);
  background-size: auto;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important;
  font-size: 40px;
}

p {
  font-size: 20px;
  line-height: 1.4em;
}

button {
  background: transparent ;
  display: inline-block;
  box-shadow: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 3px;
  line-height: 1.4em;
  border: 1px solid #fff;
  color: #fff;
  padding: 14px 40px;
  border-radius: 3px;
}

.row {
  width: 100%;
  padding: 0 40px;
}


.annotation {
  cursor: pointer;
  outline: none;
  border: none;
  font-size: 8px;
  font-weight: 300;
  background: black;
  color: #f0f0f0;
  padding: 2px 10px;
  border-radius: 20px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}